import React, { Fragment } from 'react';

import Layout from '@components/Layout';
import { Grid } from '@components/Grid';
import SEO from '@components/SEO';

const NotFoundPage = () => (
    <Layout>
        <Grid>
            <SEO title={'404'} />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90vh',
                    flexDirection: 'column',
                }}
            >
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn't exist... the sadness.</p>
            </div>
        </Grid>
    </Layout>
);

export default NotFoundPage;
